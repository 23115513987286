<template>
  <div>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <b-field>
      <input
        :id="localRef"
        :ref="localRef"
        class="inputfile"
        type="file"
        :accept="accept"
        @change="processFile"
      />
      <label :for="localRef" class="button is-primary">{{ label }}</label>
    </b-field>
    <button v-if="showClearButton" class="button is-small" @click="removeFiles">
      {{ clearLabel }}
    </button>
    <b-message
      v-if="error"
      :title="$t('There was a problem while processing the file')"
      type="is-danger"
    >
      <strong>
        <p>{{ error.source }}</p>
        <p>{{ $t('Error code') }}: {{ error.statusCode }}</p>
        <p>{{ $t('Error message') }}: {{ error.message }}</p>
        <br />
        <p>{{ $t('Please try again or contact an administrator') }}</p>
      </strong>
    </b-message>
  </div>
</template>

<script>
export default {
  props: {
    localRef: {
      type: String,
      required: true,
    },
    emitType: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    isText: {
      type: Boolean,
      required: true,
      default: true,
    },
    accept: {
      type: String,
      required: true,
    },
    clearLabel: {
      type: String,
      required: false,
      default: 'Clear loaded files',
    },
    showClearButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      fileReader: new FileReader(),
    };
  },
  methods: {
    removeFiles() {
      this.$refs[this.localRef].value = '';
    },
    processFile(ev) {
      const file = ev.target.files[0];
      let self = this;
      this.fileReader.onload = () => {
        self.$emit('fileLoaded', this.fileReader.result);
        this.removeFiles();
      };
      if (this.isText) {
        this.fileReader.readAsText(file);
      } else {
        this.fileReader.readAsArrayBuffer(file);
      }
    },
  },
};
</script>

<style scoped>
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>

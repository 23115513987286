<template>
  <validation-observer v-slot="{ valid }">
    <div class="container tile is-ancestor is-fluid">
      <div style="padding-top: 10px" class="tile has-background-white-ter">
        <b-loading
          :is-full-page="isFullPage"
          :active.sync="isLoading"
        ></b-loading>
        <div class="tile is-parent">
          <div class="tile is-child box is-3">
            <file-local-loader
              local-ref="resLocs"
              label="Upload Resource Locations"
              :emit-type="'resourceLoad'"
              :is-text="true"
              accept="csv"
              :show-clear-button="false"
              @fileLoaded="processResourceData"
            />
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Travel time"
            >
              <b-field label="Travel time">
                <b-input v-model="maxTime" icon="car" pack="fas"></b-input>
              </b-field>
              <span class="help is-danger">{{ errors[0] }}</span>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Travel date"
            >
              <b-field label="Date for resource coverage">
                <b-datepicker
                  v-model="resourceDate"
                  placeholder="Click to select..."
                  icon="calendar-alt"
                  pack="fas"
                ></b-datepicker>
              </b-field>
              <span class="help is-danger">{{ errors[0] }}</span>
            </validation-provider>
            <strong>Travel start time</strong>
            <b-field>
              <b-timepicker
                v-model="startDateTime"
                size="is-small"
                :increment-minutes="5"
                icon="clock"
              ></b-timepicker>
            </b-field>
            <b-field label="Supply file of lat/longs?">
              <b-switch v-model="latLngFile" true-value="Yes" false-value="No">
                {{ latLngFile }}
              </b-switch>
            </b-field>
            <!-- <b-field label="Load job locations"> -->
            <file-local-loader
              local-ref="jobLocs"
              :label="$t('Load job locations')"
              :is-text="true"
              accept=".csv"
              @fileLoaded="processActivityData"
            />
            <!-- </b-field> -->
            <b-field v-if="polylineGroup" label="Toggle coverage">
              <b-switch
                v-model="showIsochrone"
                true-value="On"
                false-value="Off"
                >{{ showIsochrone }}</b-switch
              >
            </b-field>
            <b-field v-if="markerGroup" label="Toggle resource pins">
              <b-switch
                v-model="showResourcePin"
                true-value="On"
                false-value="Off"
                >{{ showResourcePin }}</b-switch
              >
            </b-field>
            <b-field v-if="heatmap" label="Toggle heatmap">
              <b-switch v-model="showHeatmap" true-value="On" false-value="Off">
                {{ showHeatmap }}
              </b-switch>
            </b-field>

            <a
              :href="jobCsvTemplate"
              download="job-template.csv"
              class="has-text-link"
              >Download CSV Job Template</a
            >
            <br />
            <a
              :href="resourceCsvTemplate"
              download="resource-template.csv"
              class="has-text-link"
              >Download CSV Resource Template</a
            >
          </div>
          <div class="tile is-vertical">
            <div class="tile box is-child">
              <b-modal
                ref="imageModal"
                scroll="keep"
                :active.sync="isImageModalActive"
              >
                <div id="imageModalCanvas"></div>
              </b-modal>
              <div
                id="mapContainer"
                ref="map"
                class="fullscreen-map"
                :style="normalMapStyle"
              ></div>
              <!-- <fullscreen ref="fullscreen" @change="fullscreenChange"> -->
              <!-- <div ref="map" :style="{ width: '100%', height: '863px' }"></div> -->
              <button
                class="button is-small is-info"
                type="button"
                @click="toggleFullScreen"
              >
                {{ $t('Fullscreen') }}
              </button>
              <!-- </fullscreen> -->
              <span v-show="error" class="help is-danger">{{ error }}</span>
            </div>
            <div class="tile box is-child is-11">
              <div class="buttons">
                <a
                  :disabled="!valid"
                  class="button is-info"
                  style="width: 220px"
                  @click="getIsochrones"
                  >Calculate resource coverage</a
                >
                <b-tooltip
                  label="Right click -> save on the resulting pop-up to save the currently displayed map image"
                  animated
                  multilined
                  type="is-dark"
                >
                  <a
                    class="button is-dark"
                    style="width: 220px"
                    @click="capture"
                    >Capture Map Image</a
                  >
                </b-tooltip>
              </div>
              <div class="buttons">
                <a
                  class="button is-danger"
                  style="width: 220px"
                  @click="removeIsochrones"
                  >Discard Resource Coverage</a
                >
                <a
                  class="button is-danger"
                  style="width: 220px"
                  @click="removeHeatmap"
                  >Discard Heatmap</a
                >
              </div>
              <p>
                Resource Start Locations:
                <strong>{{ startLocationCount }}</strong>
              </p>
              <p>
                Activities Retrieved:
                <strong>{{ activityCount }}</strong>
              </p>
              <p>
                Coverage Date:
                <strong>
                  {{ formatDate(resourceDate) }}
                  {{ startDateTimeMoment.format('HH:mm') }} ({{ resourceDay }})
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import axios from 'axios';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import configData from '@/config/config.json';
import moment from 'moment';
import { backOff } from 'exponential-backoff';

import FileLocalLoader from '@/components/ofsc/loaders/FileLocalLoader';
import Papa from 'papaparse';

import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';
import loadJs from '@/lib/loadJs';
import loadCss from '@/lib/loadCss';

setInteractionMode('aggressive');

const contractorIconColor = 'rgba(251, 84, 33, 1.0)';
const normalIconColor = 'rgba(129, 123, 213, 1.0)';
// eslint-disable-next-line max-len
const normalIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="${normalIconColor}"  d="M14.24 4.559c1.705 2.816-1.52 5.378-3.858 3.539 1.902.49 4.171-1.303 3.858-3.539zm-1.24 7.351v10.09l-2 2v-12.09c-2.836-.477-5-2.938-5-5.91 0-3.314 2.687-6 6-6s6 2.687 6 6c0 2.972-2.164 5.433-5 5.91zm3-5.91c0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4 4-1.794 4-4z"/></svg>`;
// eslint-disable-next-line max-len
const contractorIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="${contractorIconColor}" d="M14.24 4.559c1.705 2.816-1.52 5.378-3.858 3.539 1.902.49 4.171-1.303 3.858-3.539zm-1.24 7.351v10.09l-2 2v-12.09c-2.836-.477-5-2.938-5-5.91 0-3.314 2.687-6 6-6s6 2.687 6 6c0 2.972-2.164 5.433-5 5.91zm3-5.91c0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4 4-1.794 4-4z"/></svg>`;

export default {
  name: 'IsochroneCsv',
  components: {
    FileLocalLoader,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      center: {
        lat: this.$configData.isochrone.defaultCentre.lat,
        lng: this.$configData.isochrone.defaultCentre.lng,
      },
      normalIcon,
      contractorIcon,
      isImageModalActive: false,
      map: {},
      polylineGroup: null,
      markerGroup: null,
      defaultLayers: null,
      hereUi: null,
      fullscreen: false,
      latLngFile: 'Yes',
      latLngFileContents: null,
      file: [],
      environment: null,
      maxTime: 30,
      isLoading: false,
      isFullPage: true,
      filteredList: [],
      heatmap: null,
      showHeatmap: 'On',
      showIsochrone: 'On',
      showResourcePin: 'On',
      showTraffic: 'Off',
      showTrafficIncidents: 'Off',
      error: null,
      normalMapStyle: {
        height: '100vh',
        width: '70vw',
      },
      fullscreenMapStyle: {
        height: '100vh',
        width: '100vw',
      },
    };
  },
  computed: {
    ...mapGetters('storeHereMaps', [
      'coreLoaded',
      'serviceLoaded',
      'uiLoaded',
      'eventsLoaded',
      'uiCssLoaded',
      'allLoaded',
    ]),
    ...mapGetters('storeIsochroneCsv', ['polygons']),
    day() {
      if (this.resourceDate) {
        let dateMoment = moment(this.resourceDate);
        return dateMoment.format('ddd').toLowerCase();
      } else {
        return null;
      }
    },
    mapWidth() {
      if (this.fullscreen) {
        return '100vw';
      } else {
        return '72vw';
      }
    },
    mapHeight() {
      if (this.fullscreen) {
        return '100vw';
      } else {
        return '45vw';
      }
    },
    resourceCsvTemplate() {
      return require('@/assets/csv/isoResourceCSV.csv');
    },
    jobCsvTemplate() {
      return require('@/assets/csv/isoJobCSV.csv');
    },
    resourceLoaderURL() {
      return this.getResourceURL();
    },
    jobLoaderURL() {
      return this.getJobURL();
    },
    startLocationCount() {
      return this.$store.getters['storeIsochroneCsv/startLocationCount'];
    },
    polygonCount() {
      return this.$store.getters['storeIsochroneCsv/polygonCount'];
    },
    activities() {
      return this.$store.getters['storeIsochroneCsv/activities'];
    },
    activityCount() {
      return this.$store.getters['storeIsochroneCsv/activityCount'];
    },
    resourceDay() {
      if (this.resourceDate) {
        return moment(this.resourceDate).format('dddd');
      } else {
        return '';
      }
    },
    resourceDate: {
      get() {
        let epochBasedDate =
          this.$store.getters['storeIsochroneCsv/resourceDate'];
        return new Date(epochBasedDate);
      },
      set(val) {
        this.$store.dispatch(
          'storeIsochroneCsv/setResourceDate',
          val.valueOf(),
        );
      },
    },
    startDateTime: {
      get() {
        let unixTime = this.$store.getters['storeIsochroneCsv/startDateTime'];
        let startObj = new Date(unixTime);
        return startObj;
      },
      set(val) {
        let newDate;
        if (!this.isValidDate(val)) {
          newDate = new Date();
          newDate.setHours(7);
          newDate.setMinutes(0);
        } else {
          newDate = val.valueOf();
        }
        this.$store.dispatch('storeIsochroneCsv/setStartDateTime', newDate);
      },
    },
    startDateTimeMoment() {
      return this.startDateTime ? moment(this.startDateTime) : moment();
    },
  },
  watch: {
    showIsochrone(newVal) {
      if (newVal === 'Off') {
        this.map.removeObject(this.polylineGroup);
      } else {
        this.map.addObject(this.polylineGroup);
      }
    },
    showResourcePin(newVal) {
      if (newVal === 'Off') {
        this.map.removeObject(this.markerGroup);
      } else {
        this.map.addObject(this.markerGroup);
      }
    },
    showHeatmap(newVal) {
      if (newVal === 'On') {
        this.map.addLayer(this.heatmap);
      } else {
        this.map.removeLayer(this.heatmap);
      }
    },
  },
  mounted() {
    this.getMap(() => {
      this.initMap(() => {
        /* noop */
      });
    });
  },
  created() {
    /* eslint-disable no-undef */
    if (!this.allLoaded) {
      loadJs(
        'https://js.api.here.com/v3/3.1/mapsjs-core.js',
        () => {
          this.SET_CORE_LOADED(true);
        },
        document.body,
        false,
        true,
      );
    }
    loadJs(
      'https://js.api.here.com/v3/3.1/mapsjs-service.js',
      () => {
        this.SET_SERVICE_LOADED(true);
      },
      document.body,
      false,
      true,
    );
    loadJs(
      'https://js.api.here.com/v3/3.1/mapsjs-ui.js',
      () => {
        this.SET_UI_LOADED(true);
      },
      document.body,
      false,
      true,
    );
    loadJs(
      'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js',
      () => {
        this.SET_EVENTS_LOADED(true);
      },
      document.body,
      false,
      true,
    );
    loadCss(
      'https://js.api.here.com/v3/3.1/mapsjs-ui.css',
      () => {
        this.SET_UI_CSS_LOADED(true);
      },
      document.head,
    );

    loadJs(
      'https://js.api.here.com/v3/3.1/mapsjs-clustering.js',
      () => {
        this.SET_CLUSTERING_LOADED(true);
      },
      document.body,
      false,
      true,
    );
    loadJs(
      'https://js.api.here.com/v3/3.1/mapsjs-data.js',
      () => {
        this.SET_MAP_DATA_LOADED(true);
      },
      document.body,
      false,
      true,
    );
    // eventBus.$on('localCsv', (data) => {
    //   this.processLocalCsv(data);
    //   this.isLoading = true;
    // });
  },
  methods: {
    ...mapMutations('storeHereMaps', [
      'SET_CORE_LOADED',
      'SET_SERVICE_LOADED',
      'SET_UI_LOADED',
      'SET_EVENTS_LOADED',
      'SET_UI_CSS_LOADED',
      'SET_CLUSTERING_LOADED',
      'SET_MAP_DATA_LOADED',
    ]),
    ...mapActions('storeIsochroneCsv', ['addPolygon']),
    getPolygon(id) {
      return this.polygons[id];
    },
    initMap(callback) {
      if (this.initComplete) callback();
      else {
        this.platform = new H.service.Platform({
          apikey: this.$configData.mapConfig.apiKey,
        });
        this.markerGroup = new H.map.Group();
        this.pixelRatio = window.devicePixelRatio || 1;
        this.defaultLayers = this.platform.createDefaultLayers({
          tileSize: this.pixelRatio === 1 ? 256 : 512,
          ppi: this.pixelRatio === 1 ? undefined : 320,
        });
        this.search = this.platform.getSearchService();
        this.defaultLayers.crossOrigin = true;
        this.map = new H.Map(
          this.$refs.map,
          this.defaultLayers.vector.normal.map,
          {
            zoom: this.$configData.isochrone.defaultCentre.zoom || 8,
            center: this.center,
            pixelRatio: this.pixelRatio,
          },
        );
        window.addEventListener('resize', () =>
          this.map.getViewPort().resize(),
        );
        this.behavior = new H.mapevents.Behavior(
          new H.mapevents.MapEvents(this.map),
        );

        // Create the default UI components
        this.ui = H.ui.UI.createDefault(this.map, this.defaultLayers);
        this.ui.setUnitSystem(
          this.imperial ? H.ui.UnitSystem.IMPERIAL : H.ui.UnitSystem.METRIC,
        );
        this.map.addObject(this.markerGroup);
        this.router = this.platform.getRoutingService();
        this.initComplete = true;
        callback();
        // this.showPostcodes();
      }
    },
    getMap(callback) {
      let vm = this;
      function checkIfLoaded() {
        if (vm.allLoaded) callback();
        else setTimeout(checkIfLoaded, 200);
      }
      checkIfLoaded();
    },
    showPostcodes() {
      const service = this.platform.getPlatformDataService();
      const bubble = new H.ui.InfoBubble(this.map.getCenter(), {
        content: '',
      });
      bubble.close();
      this.ui.addBubble(bubble);

      const style = new H.map.SpatialStyle();
      // create tile provider and layer that displays postcode boundaries
      const boundariesProvider =
        new H.service.extension.platformData.TileProvider(
          service,
          {
            layerId: 'PSTLCB_UNGEN',
            level: 12,
          },
          {
            resultType:
              H.service.extension.platformData.TileProvider.ResultType.POLYLINE,
            styleCallback: function () {
              return style;
            },
          },
        );
      const boundaries = new H.map.layer.TileLayer(boundariesProvider);
      this.map.addLayer(boundaries);

      const svg = `<svg width="80" height="24" xmlns="http://www.w3.org/2000/svg"><text x="0" y="20" font-size="10pt" font-family="Arial">{POSTCODE}</text></svg>`;

      // create tile provider and layer that displays postcode centroids
      const centroidsProvider =
        new H.service.extension.platformData.TileProvider(
          service,
          {
            layerId: 'PSTLCB_MP',
            level: 12,
          },
          {
            resultType:
              H.service.extension.platformData.TileProvider.ResultType.MARKER,
            styleCallback(data) {
              const postcode = data.getCell('POSTAL_CODE');
              const postcodeSvg = svg.replace('{POSTCODE}', postcode);
              const icon = new H.map.Icon(postcodeSvg);
              return icon;
            },
          },
        );
      const centroids = new H.map.layer.MarkerTileLayer(centroidsProvider);
      this.map.addLayer(centroids);

      // add event listener that shows infobubble with basic information
      // about the postcode
      centroidsProvider.addEventListener('tap', this.handlePostcodeTap, false);
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        this.$refs.map.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
    async processResourceData(data) {
      this.isLoading = true;
      try {
        if (!data) return;
        const jsonDataWrapper = {
          csv: data,
        };
        const result = await axios({
          method: 'post',
          url: configData.isochroneCsv.resourceUrl,
          data: jsonDataWrapper,
          headers: {
            Authorization: `Bearer ${this.idToken}`,
          },
        });
        this.processResourceLoad(result.data);
      } finally {
        this.isLoading = false;
      }
    },
    async processActivityData(data) {
      this.isLoading = true;
      try {
        if (!data) return;
        const jsonDataWrapper = {
          csv: data,
        };
        const options = {
          method: 'post',
          url: configData.isochroneCsv.jobUrl,
          data: jsonDataWrapper,
          headers: {
            Authorization: `Bearer ${this.idToken}`,
          },
        };
        const result = await axios(options);
        this.processJobLoad(result.data);
      } finally {
        this.isLoading = false;
      }
    },
    // Access to individual map elements on the geoJson file
    convertToMapObject(objects) {
      let hasObjects =
        objects.getObjects != null &&
        typeof objects.getObjects() !== 'undefined' &&
        objects.getObjects().length >= 1;

      // if the parsed element has nested map elements, call this function again
      if (hasObjects) {
        objects.getObjects().forEach((innerObject) => {
          this.convertToMapObject(innerObject);
        });
      }
    },
    capture() {
      // Capturing area of the map is asynchronous, callback function receives HTML5 canvas
      // element with desired map area rendered on it.
      // We also pass an H.ui.UI reference in order to see the ScaleBar in the output.
      // If dimensions are omitted, whole veiw port will be captured
      this.map.capture(
        (canvas) => {
          if (canvas) {
            this.isImageModalActive = true;
            this.$nextTick(() => {
              let div = document.getElementById('imageModalCanvas');
              div.appendChild(canvas);
            });
          } else {
            // For example when map is in Panorama mode
            this.$buefy.modal.open(`<p>'Capturing is not supported'</p>`);
          }
        },
        [this.hereUi],
      );
    },
    removeHeatmap() {
      if (this.heatmap) {
        this.map.removeLayer(this.heatmap);
      }
    },
    async processJobLoad(data) {
      this.isLoading = true;
      this.$store.dispatch('storeIsochroneCsv/discardActivities', data);
      this.$store.dispatch('storeIsochroneCsv/storeActivities', data);
      this.startClustering();
      this.isLoading = false;
    },
    async processResourceLoad(data) {
      this.$store.dispatch('storeIsochroneCsv/discardStartLocations', data);
      this.startLocs = data;
      this.$store.dispatch('storeIsochroneCsv/storeStartLocations', data);
      this.getIsochrones();
    },
    async processLocalCsv(data) {
      this.isLoading = true;
      let locationArray = Papa.parse(data, {
        dynamicTyping: false,
        preview: 0,
        download: false,
        skipEmptyLines: false,
      });
      this.latLngFileContents = [];
      this.$store.dispatch('storeIsochroneCsv/discardAllData');
      let activities = [];
      for (let i = 1; i < locationArray.data.length; i++) {
        let activity = {};
        activity.geocode = {};
        activity.geocode.lat = locationArray.data[i][0];
        activity.geocode.lng = locationArray.data[i][1];
        activities.push(activity);
      }

      await this.$store.dispatch(
        'storeIsochroneCsv/storeActivities',
        activities,
      );

      this.startClustering();
      this.isLoading = false;
    },
    toggleFullscreen() {
      this.$refs['fullscreen'].toggle();
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },
    getResourceURL() {
      let url = configData.isochrone.resourceUrl;
      if (!url) {
        this.error = 'No configuration found for loader';
        return null;
      } else {
        return url;
      }
    },
    getJobURL() {
      let url = configData.isochrone.jobUrl;
      if (!url) {
        this.error = 'No configuration found for loader';
        return null;
      } else {
        return url;
      }
    },
    formatDate(date) {
      let momented = moment(date);
      if (momented.isValid()) {
        return momented.format('DD/MM/YYYY');
      } else {
        return null;
      }
    },
    getDayString(date) {
      let momented = moment(date);
      if (momented.isValid()) {
        return `(${momented.format('ddd')})`;
      } else {
        return null;
      }
    },
    formatHour(hour) {
      return hour < 10 ? '0' + hour : hour;
    },
    formatMinutes(minutes) {
      return minutes.toString().length === 1 ? '0' + minutes : minutes;
    },
    async discardData() {
      this.removeIsochrones();
      await this.$store.dispatch('storeIsochroneCsv/discardAllData');
    },
    async plotStartLocs() {
      this.discardMarkerGroup();
      if (!this.markerGroup) {
        this.markerGroup = new H.map.Group();
      }

      this.markerGroup.addEventListener('tap', this.handleTap, {
        passive: true,
      });
      if (this.showResourcePin === 'On') {
        this.map.addObject(this.markerGroup);
      }
      const mapNormalIcon = new H.map.Icon(this.normalIcon);
      const mapContractorIcon = new H.map.Icon(this.contractorIcon);

      let icon;
      this.startLocations.forEach((loc) => {
        if (this.showOnlyWorking && !loc.isWorking) {
          return;
        }
        if (this.isContractor(loc)) {
          icon = mapContractorIcon;
        } else {
          icon = mapNormalIcon;
        }
        let lat, lng;
        if (this.startOrHome === 'Start') {
          lat = loc.lat;
          lng = loc.lng;
        } else {
          lat = loc.home.lat;
          lng = loc.home.lng;
        }
        const center = new H.geo.Point(lat, lng);
        const markerPos = new H.map.Marker(center, { icon });
        // Add the polygon and marker to the map:
        markerPos.setData({
          resourceId: loc.resourceId,
          name: loc.name,
          type: loc.resourceType,
          address: '',
        });

        this.markerGroup.addObject(markerPos);
      });
      this.zoomToBoundingBox();
    },
    zoomToBoundingBox() {
      this.map.getViewModel().setLookAtData({
        bounds: this.markerGroup.getBoundingBox(),
      });
    },
    async getIsochrones() {
      this.error = null;
      if (this.polylineGroup) {
        try {
          this.map.removeObject(this.polylineGroup);
        } catch (err) {
          // don't mind if this fails
        }
      }
      if (this.markerGroup) {
        try {
          this.map.removeObject(this.markerGroup);
        } catch (err) {
          // don't mind if this fails
        }
      }
      try {
        this.isochroneCount = 0;
        this.isLoading = true;
        let queryDate = moment(this.resourceDate);
        queryDate.hour(this.startDateTimeMoment.hour());
        queryDate.minute(this.startDateTimeMoment.minutes());
        queryDate.second(0);
        let queryDateString = queryDate.format('YYYY-MM-DDTHH:mm:ss');
        let startLocs = this.$store.getters['storeIsochroneCsv/startLocations'];
        /* eslint-disable no-undef */
        this.polylineGroup = new H.map.Group();
        this.markerGroup = new H.map.Group();
        this.map.addObject(this.polylineGroup);
        this.map.addObject(this.markerGroup);
        const mapNormalIcon = new H.map.Icon(this.normalIcon);
        // Define a callback function to process the isoline response

        const buildLineString = (result) => {
          const isolineCoords = result.response.isoline[0].component[0].shape;
          const center = new H.geo.Point(
            result.response.center.latitude,
            result.response.center.longitude,
          );
          // Add the returned isoline coordinates to a linestring:
          const linestring = new H.geo.LineString();
          isolineCoords.forEach(function (coords) {
            linestring.pushLatLngAlt.apply(linestring, coords.split(','));
          });

          // Create a polygon and a marker representing the isoline:
          const isolinePolygon = new H.map.Polygon(linestring);
          const isolineCenter = new H.map.Marker(center, {
            icon: mapNormalIcon,
          });
          // Add the polygon and marker to the map:
          this.markerGroup.addObject(isolineCenter);
          this.polylineGroup.addObject(isolinePolygon);
        };

        startLocs.forEach(async (loc) => {
          const routingParams = {
            mode: 'fastest;car;traffic:enabled',
            start: `geo!${loc.lat},${loc.lng}`,
            range: this.maxTime * 60,
            rangetype: 'time',
            departure: queryDateString,
            routingMode: 'fast',
            apiKey: this.$configData.mapConfig.apiKey,
          };
          const params = new URLSearchParams(routingParams);
          const url = `https://isoline.route.ls.hereapi.com/routing/7.2/calculateisoline.json`;

          const existing = this.getPolygon(loc.id);
          if (existing) {
            buildLineString(existing);
            return;
          }
          // Call the Routing API to calculate an isoline:
          try {
            const { data } = await backOff(
              () =>
                axios.request({
                  method: 'get',
                  url,
                  params,
                }),
              {
                numOfAttempts: Infinity,
                timeMultiple: 1.2,
                maxDelay: 5000,
                startingDelay: 10,
              },
            );
            await this.addPolygon({
              id: loc.id,
              response: {
                center: data.response.center,
                isoline: data.response.isoline,
              },
            });
            buildLineString(data);
          } catch (err) {
            console.error(`Failed to isoline`, err);
          }
        });
        this.zoomToBoundingBox();
      } catch (err) {
        console.error('failed processing isochrones', err);
      } finally {
        this.isLoading = false;
      }
    },
    removeIsochrones() {
      this.map.removeObject(this.polylineGroup);
    },
    startClustering() {
      this.removeHeatmap();
      this.isLoading = true;

      // First we need to create an array of DataPoint objects,
      // for the ClusterProvider
      const dataPoints = this.activities.map((item) => {
        return new H.clustering.DataPoint(item.lat, item.lng);
      });
      // Create a clustering provider with custom options for clusterizing the input
      const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
        clusteringOptions: {
          // Maximum radius of the neighbourhood
          eps: 32,
          // minimum weight of points required to form a cluster
          minWeight: 2,
        },
      });

      // Create a layer tha will consume objects from our clustering provider
      this.heatmap = new H.map.layer.ObjectLayer(clusteredDataProvider);
      // To make objects from clustering provder visible,
      // we need to add our layer to the map
      this.map.addLayer(this.heatmap);
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
#fullscreen-map:-webkit-full-screen {
  width: 100%;
  height: 100%;
}
</style>
